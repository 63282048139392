<template>
  <AffiliateLayout>
    <section>
      <div class="mb-2">
        <b-link to="/affiliate/payment/list?tab=2">
          <feather-icon
            size="24"
            icon="ArrowLeftIcon"
          />
          <span>Quay lại</span>
        </b-link>
      </div>
      <b-row class="row2">
        <!-- <b-col md="3">
          <b-card
            v-if="memberInfo"
            class="card-sticky"
          >
            <div class="mb-2">
              <h4 class="text-primary">
                Cộng tác viên
              </h4>
            </div>
            <div class="mb-2 text-center">
              <b-avatar
                size="70px"
                :src="memberInfo.customer[0].avatar || '/logo.png'"
              />
            </div>
            <div class="mb-2 text-center">
              <h3 class="text-primary mb-1">
                {{ memberInfo.customer[0].fullname }}
              </h3>

              <b-badge variant="light-success mb-1">
                Xếp hạng: #100
              </b-badge>

              <div class="d-flex justify-content-between">
                <h3 class="text-warning">
                  {{ memberInfo.customer[0].point }} IFS
                </h3>
                <router-link to="points-history">
                  <small>Lịch sử điểm</small>
                  <feather-icon
                    icon="ArrowRightIcon"
                    class="ml-25"
                  />
                </router-link>
              </div>
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex flex-column mb-1">
                <strong>Sinh nhật</strong>
                <span>{{ memberInfo.customer[0].birthday || 'Không xác định' }}</span>
              </div>
              <div class="d-flex flex-column mb-1">
                <strong>CMND / CCCD</strong>
                <span>{{ memberInfo.cmnd }}</span>
              </div>

              <div class="d-flex flex-column">
                <strong>Địa chỉ</strong>
                <span>{{ memberInfo.customer[0].address || 'Không xác định' }}</span>
              </div>

              <div
                v-for="(item, index) in memberInfo.bank"
                :key="index"
              >
                <div class="d-flex flex-column mt-1">
                  <strong>Ngân hàng</strong>
                  <span>{{ item.name || 'Không xác định' }}</span>
                  <strong>STK</strong>
                  <span>{{ item.account_number || 'Không xác định' }}</span>
                </div>
              </div>
            </div>
          </b-card>
        </b-col> -->
        <b-col md="12">
          <!-- CUSTOMER LIST -->
          <b-card>
            <div class="mb-2 d-flex">
              <b-form-group class="mr-2">
                <b-form-radio-group
                  v-model="selectedRadio"
                  button-variant="primary"
                  :options="optionsRadio"
                  buttons
                  @change="setDefaultDateRange(selectedRadio)"
                />
              </b-form-group>

              <b-form-group>
                <flat-pickr
                  v-if="readyDete"
                  v-model="orderDatePicker.fromToDate"
                  class="form-control d-inline-block"
                  :config="configDateRangePicker"
                  placeholder="Ngày bắt đầu và kết thúc"
                  style="min-width: 220px;"
                />
              </b-form-group>
            </div>

            <div class="history-grid mb-3">
              <div class="history-grid__item">
                <h4>Tổng doanh thu</h4>
                <h3 class="text-primary font-weight-bolder mb-0">
                  {{ unitFormatOriginal(stData.revenue) }}
                </h3>
              </div>
              <div class="history-grid__item">
                <h4>Đã nhận tiền</h4>
                <h3 class="text-warning font-weight-bolder mb-0">
                  {{ unitFormatOriginal(stData.history_for_control_success) }}
                </h3>
              </div>
              <div class="history-grid__item">
                <h4>Bị từ chối</h4>
                <h3 class="text-danger font-weight-bolder mb-0">
                  {{ unitFormatOriginal(stData.history_for_control_fail) }}
                </h3>
              </div>
              <!-- <div class="history-grid__item">
                <h4>Lợi nhuận chưa trả</h4>
                <h3 class="text-warning mb-0">
                  200
                </h3>
              </div> -->
            </div>

            <h4 class="text-primary mb-2">
              Lịch sử giao dịch
            </h4>
            <div class="filter-tab w-100 mb-2">
              <div
                v-for="item in filterTabs"
                :key="item.id"
                :class="['filter-tab__item', {'active': filterSelected == item.id}]"
                @click="filterSelected = item.id"
              >
                {{ $t(item.label) }}
              </div>
            </div>
            <div class="d-flex">
              <b-input-group class="mb-2">
                <b-form-input
                  v-model="orderHistorySearchTerm"
                  placeholder="Nhập mã đơn..."
                />
                <b-input-group-append>
                  <b-button variant="outline-primary">
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
            <vue-good-table
              class="custom-good-table mt-2"
              style-class="vgt-table"
              :columns="dataFields"
              :rows="items"
              :line-numbers="false"
              :search-options="{
                enabled: true,
                externalQuery: customerSearchTerm
              }"
              :pagination-options="{
                enabled: true,
                perPage: customerPageLength
              }"
            >
              <!-- Table: Head -->
              <template
                slot="table-column"
                slot-scope="props"
              >
                <span>
                  {{ $t(props.column.label) }}
                </span>
              </template>

              <!-- Table: Rows -->
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Rank -->
                <span
                  v-if="props.column.field === 'created_at'"
                  class="d-flex flex-wrap align-items-center"
                >
                  <span
                    class="d-block line-clamp"
                    style="max-width: 320px;"
                  >
                    {{ props.row.created_at }}
                  </span>
                </span>

                <!-- Column: Description -->
                <span
                  v-else-if="props.column.field === 'id'"
                  class="d-flex flex-wrap align-items-center"
                >
                  <span
                    class="d-block line-clamp"
                    style="max-width: 320px;"
                    :title="props.row.id"
                  >
                    {{ props.row.id }}
                  </span>
                </span>

                <!-- Column: Description -->
                <span
                  v-else-if="props.column.field === 'status'"
                  class="d-flex flex-wrap align-items-center"
                >
                  <span
                    class="d-block line-clamp"
                    style="max-width: 320px;"
                    :title="props.row.status"
                  >
                    {{ props.row.status }}
                  </span>
                </span>

                <!-- Column: Actions -->
                <span
                  v-else-if="props.column.field === 'content'"
                  class="text-center"
                  style="width: 280px"
                >
                  {{ props.row.content }}
                  <b-link
                    v-if="props.row.type === 1"
                    :to="`/order/edit/${props.row.id_order}`"
                  >{{ props.row.order_no }}</b-link>
                </span>

                <span
                  v-else-if="props.column.field === 'price'"
                  class="d-inline-flex text-center"
                >
                  {{ props.row.type === 1 ? `+${unitFormatOriginal(props.row.price)}` : `-${unitFormatOriginal(props.row.price)}` }}
                </span>

                <span
                  v-else-if="props.column.field === 'type'"
                  class="d-inline-flex text-center"
                  :class="props.row.type === 2 ? 'text-warning' : props.row.type === 1 ? 'text-success' : 'text-danger' "
                >
                  {{ statusOptions[`st_${props.row.type}`] }}
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap p-2">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="customerPageLength"
                      :options="['10','30','100']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage: value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="customerPageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage: value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
        </b-col>
      </b-row>

      <!-- REFERRAL REJECT MODAL -->
      <b-modal
        id="modal-referral-reject"
        size="sm"
        centered
        title="Từ chối Referral"
        header-bg-variant="danger"
        header-text-variant="light"
        header-close-variant="danger"
        title-tag="h4"
        ok-title="Gởi"
        ok-variant="danger"
        cancel-title="Thoát"
        cancel-variant="light"
      >
        <b-form-group
          label="Nhập lý do"
        >
          <b-form-textarea
            placeholder="Nhập lý do"
            rows="5"
          />
        </b-form-group>
      </b-modal>

      <!-- BUYER INFO MODAL -->
      <b-modal
        v-model="buyerInfoShow"
        centered
        title="Thông tin khách hàng"
        header-bg-variant="primary"
        header-text-variant="light"
        header-close-variant="danger"
        title-tag="h4"
        ok-title="Đóng"
        ok-variant="primary"
        :ok-only="true"
      >
        <div
          v-if="buyerInfo"
        >
          <b-form-group>
            <div class="img-avata">
              <img
                :src="buyerInfo.avatar || '/logo.png'"
                :alt="buyerInfo.fullname"
              >
            </div>
          </b-form-group>
          <b-row
            class="row2 mb-2"
            style="row-gap: 0.5rem"
          >
            <b-col sm="12">
              <h4>Thông tin cơ bản</h4>
            </b-col>
            <b-col sm="6">
              <b-form-group
                class="mb-0"
                label="Tên đầy đủ"
              >
                <h5>{{ buyerInfo.fullname }}</h5>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                class="mb-0"
                label="Giới tính"
              >
                <h5>{{ buyerInfo.gender == 0 ? 'Nữ' : 'Nam' }}</h5>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                class="mb-0"
                label="Ngày sinh"
              >
                <h5>{{ buyerInfo.birthday || 'Không xác định' }}</h5>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                class="mb-0"
                label="Số điện thoại"
              >
                <h5>{{ buyerInfo.phone || 'Không xác định' }}</h5>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                class="mb-0"
                label="Email"
              >
                <h5>{{ buyerInfo.email || 'Không xác định' }}</h5>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                class="mb-0"
                label="Địa chỉ"
              >
                <h5>{{ buyerInfo.address || 'Không xác định' }}</h5>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            class="row2"
            style="row-gap: 0.5rem"
          >
            <b-col sm="12">
              <h4>Thông tin thêm</h4>
            </b-col>
            <b-col sm="6">
              <b-form-group
                class="mb-0"
                label="Mạng xã hội"
              >
                <h5>{{ buyerInfo.social || 'Không xác định' }}</h5>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Nguồn"
                class="mb-0"
              >
                <h5>{{ sourceRender(buyerInfo.source) || 'Không xác định' }}</h5>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Ghi chú"
                class="mb-0"
              >
                <h5>{{ buyerInfo.note || 'Không xác định' }}</h5>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-modal>
    </section>
  </AffiliateLayout>
</template>

<script>
// import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormTextarea,
  BFormSelect,
  BPagination,
  BModal,
  BFormRadioGroup,
  BLink,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import MixinAffiliate from '../mixin'
import AffiliateLayout from '../layouts/AffiliateLayout.vue'

export default {
  name: 'MemberDetail',
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormTextarea,
    BFormSelect,
    BPagination,
    BModal,
    BFormRadioGroup,
    VueGoodTable,
    AffiliateLayout,
    flatPickr,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BButton,
  },
  mixins: [MixinAffiliate],
  props: {},

  data() {
    return {
      readyDete: true,
      memberInfo: null,
      customerList: [],
      optionsRadio: [
        { text: '24h', value: 1 },
        { text: '7 Ngày', value: 7 },
        { text: '30 Ngày', value: 30 },
      ],
      orderHistoryFields: [
        {
          field: 'order_no', label: 'Mã đơn hàng', sortable: false, thClass: 'text-left',
        },
        {
          field: 'total', label: 'Giá bán', sortable: false, thClass: 'text-left', tdClass: 'text-left',
        },
        {
          field: 'profit', label: 'Lợi nhuận', sortable: false, tdClass: 'text-left',
        },
        {
          field: 'status_text', label: 'Trạng thái', sortable: false, tdClass: 'text-center',
        },
      ],
      orderHistoryItems: [],
      orderHistoryPageLength: 10,
      orderHistorySearchTerm: '',
      orderDatePicker: {
        startDate: null,
        endDate: null,
        fromToDate: null,
      },
      orderHistoryBox: null,
      configDateRangePicker: {
        mode: 'range',
        dateFormat: 'd-m-Y',
        locale: Vietnamese,
        onChange: async ([start, end]) => {
          if (start && end) {
            this.orderDatePicker.startDate = start.toLocaleDateString('en-ZA').replaceAll('/', '-')
            this.orderDatePicker.endDate = end.toLocaleDateString('en-ZA').replaceAll('/', '-')
            this.litParams.date_from = this.orderDatePicker.startDate
            this.litParams.date_to = this.orderDatePicker.endDate
          }
        },
      },
      filterSelected: 'all',
      filterTabs: [
        {
          id: 'all',
          label: 'All',
        },
        {
          id: 'cong-tien',
          label: 'Cộng tiền',
        },
        {
          id: 'rut-tien',
          label: 'Rút tiền',
        },
        {
          id: 'tu-choi',
          label: 'Bị từ chối',
        },
      ],
      dataFields: [
        {
          field: 'created_at', label: 'Thời gian', sortable: false, thClass: 'text-left',
        },
        {
          field: 'id', label: 'Mã giao dịch', sortable: false, thClass: 'text-left', tdClass: 'text-left',
        },
        {
          field: 'status', label: 'Loại giao dịch', sortable: false, thClass: 'text-left',
        },
        {
          field: 'content', label: 'Chi tiết giao dịch', sortable: false, tdClass: 'text-center w-300',
        },
        {
          field: 'price', label: 'Số tiền giao dịch', sortable: false, tdClass: 'text-right', thClass: 'text-right',
        },
        {
          field: 'type', label: 'Trạng thái', sortable: false, tdClass: 'text-right', thClass: 'text-right',
        },
      ],
      items: [],
      statusOptions: {
        st_1: 'Cộng tiền',
        st_2: 'Rút tiền',
        st_3: 'Bị từ chối',
      },
      referralPageLength: 10,
      customerFields: [
        {
          field: 'fullname', label: 'Tên đầy đủ', sortable: false, thClass: 'text-left',
        },
        {
          field: 'phone', label: 'SĐT', sortable: false, thClass: 'text-left', tdClass: 'text-left',
        },
        {
          field: 'email', label: 'Email', sortable: false, tdClass: 'text-left',
        },
        {
          field: 'spend', label: 'Chi tiêu', sortable: false, tdClass: 'text-center',
        },
      ],
      rangeDate: null,
      selectedRadio: null,
      customerItems: [],
      customerPageLength: 10,
      customerSearchTerm: '',
      teamFields: [
        {
          field: 'fullname', label: 'Tên đầy đủ', sortable: false, thClass: 'text-left',
        },
        {
          field: 'phone', label: 'SĐT', sortable: false, thClass: 'text-left', tdClass: 'text-left',
        },
        {
          field: 'level', label: 'Level', sortable: false, tdClass: 'text-left',
        },
        {
          field: 'turnover', label: 'Doanh thu', sortable: false, tdClass: 'text-center',
        },
      ],
      teamItems: [
        {
          fullname: 'Nguyễn Văn A1',
          phone_number: '0987654321',
          level: 'Cấp 1',
          turnover: '1.000.000₫',
        },
        {
          fullname: 'Nguyễn Văn A2',
          phone_number: '0987654321',
          level: 'Cấp 2',
          turnover: '1.000.000₫',
        },
        {
          fullname: 'Nguyễn Văn A3',
          phone_number: '0987654321',
          level: 'Cấp 1',
          turnover: '1.000.000₫',
        },
        {
          fullname: 'Nguyễn Văn A4',
          phone_number: '0987654321',
          level: 'Cấp 2',
          turnover: '1.000.000₫',
        },
        {
          fullname: 'Nguyễn Văn A5',
          phone_number: '0987654321',
          level: 'Cấp 1',
          turnover: '1.000.000₫',
        },
        {
          fullname: 'Nguyễn Văn A6',
          phone_number: '0987654321',
          level: 'Cấp 2',
          turnover: '1.000.000₫',
        },
        {
          fullname: 'Nguyễn Văn A7',
          phone_number: '0987654321',
          level: 'Cấp 1',
          turnover: '1.000.000₫',
        },
        {
          fullname: 'Nguyễn Văn A8',
          phone_number: '0987654321',
          level: 'Cấp 2',
          turnover: '1.000.000₫',
        },
      ],
      teamPageLength: 10,
      teamSearchTerm: '',
      buyerInfoShow: false,
      buyerInfo: null,
      xTimeOut: null,
      litParams: {
        customer_id: this.$route.params.id,
        date_from: '',
        date_to: '',
      },
      stData: {},
    }
  },
  watch: {
    litParams: {
      // eslint-disable-next-line func-names, space-before-function-paren
      handler() {
        this.getList()
        this.getStatic()
      },
      deep: true,
    },
  },
  created() {
    this.setDefaultDateRange(365)
  },
  methods: {
    async getList() {
      const res = await this.mixGetAffi('/api/v1/auth/balance_fluctuations', this.litParams)
      if (res.status) {
        this.items = []
        res.data.items.map(val => {
          this.items.push({
            ...val,
            id: val.trading_code,
            id_order: val.order.id,
            created_at: val.created_at,
            status: val.type === 1 ? 'Cộng tiền' : 'Trừ tiền',
            content: val.note,
            order_no: val.order.order_no,
            price: val.money,
            type: val.type,
          })
          return true
        })
      }
    },

    async getStatic() {
      const res = await this.mixGetAffi('/api/v1/customer_for_control', this.litParams)
      if (res.status) {
        this.stData = { ...res.data }
      }
    },

    statusClass(status) {
      let result = ''
      switch (status) {
        case 1:
          result = 'text-warning'
          break
        case 2:
          result = 'text-success'
          break
        case 3:
          result = 'text-danger'
          break
        default:
          result = 'text-warning'
          break
      }
      return result
    },
    setDefaultDateRange(day = 0) {
      clearTimeout(this.xTimeOut)
      this.readyDete = false
      const d = new Date()
      const time = d.getTime()
      const d2 = new Date(time - (1000 * 60 * 60 * 24 * day))
      this.configDateRangePicker.defaultDate = [d2.toISOString(), d.toISOString()]
      this.orderDatePicker.startDate = `${d2.getFullYear()}-${d2.getMonth() + 1}-${d2.getDate()}`
      this.orderDatePicker.endDate = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`

      this.litParams.date_from = this.orderDatePicker.startDate
      this.litParams.date_to = this.orderDatePicker.endDate
      this.xTimeOut = setTimeout(() => {
        this.readyDete = true
      }, 1)
    },
    onShowBuyerModal(index) {
      this.buyerInfoShow = true
      this.buyerInfo = this.customerItems[index]
    },
    sourceRender(status) {
      let result = ''
      switch (status) {
        case 1:
          result = 'facebook'
          break

        case 2:
          result = 'tiktok'
          break

        case 3:
          result = 'website'
          break

        case 4:
          result = 'affiliate'
          break

        default:
          result = 'website'
          break
      }
      return result
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.history-point {
  display: flex;
  flex-direction: column;
}
.history-point__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.history-point__item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.history-point__item .left {
  display: flex;
  align-items: center;
  gap: 16px
}
.history-point__item .content {
  margin-left: 0;
  display: flex;
  flex-direction: column;
  gap: 2px
}
.card-sticky {
  position: sticky;
  top: calc(2rem + 4.45rem);
}

.img-avata{
  background-color: #f0f0f0;
  padding: 16px;
  text-align: center;
  img{
    width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit: cover;
  }
}
</style>
